import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";

new Swup({
    plugins: [
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin({
            prefix: "bg-",
        }),
        new SwupProgressPlugin({
            delay: 500,
        }),
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
    ],
});
