import Swiper from "swiper";
import { Keyboard, Navigation } from "swiper/modules";

export default () => ({
    swiper: null,
    slideIndex: 1,

    init() {
        this.swiper = new Swiper(this.$refs.swiper, {
            loop: true,
            modules: [Navigation, Keyboard],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            keyboard: {
                enabled: true,
            },
        });

        this.swiper.on("slideChange", () => {
            this.slideIndex = this.swiper.realIndex + 1;
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },
});
