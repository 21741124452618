import "./bootstrap.js";
import "./swup.js";

import Alpine from "alpinejs";
import homeSwiper from "./components/homeSwiper.js";
import projectSwiper from "./components/projectSwiper.js";

window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
    Alpine.data("homeSwiper", homeSwiper);
    Alpine.data("projectSwiper", projectSwiper);
});

Alpine.start();
