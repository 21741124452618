import Swiper from "swiper";
import { Autoplay, Keyboard } from "swiper/modules";

export default () => ({
    swiper: null,
    autoTimeout: null,

    init() {
        this.swiper = new Swiper(this.$refs.swiper, {
            loop: true,
            modules: [Autoplay, Keyboard],
            autoplay: {
                delay: 5000,
            },
            keyboard: {
                enabled: true,
            },
        });

        this.swiper.autoplay.pause();

        this.autoTimeout = setTimeout(() => {
            if (this.swiper) {
                this.swiper.autoplay.start();
            }
        }, 2000);
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }

        clearTimeout(this.autoTimeout);
    },
});
